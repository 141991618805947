import React from 'react';
import { NextPage } from 'next';
import { Button, Icon } from '@elpassion/taco';
import { t } from '@lingui/macro';
import { ErrorIcon, Link } from '@hub/ui';
import { routes } from '../utils';

const NotFoundPage: NextPage = () => {
  return (
    <div className="flex h-screen">
      <div className="m-auto text-center">
        <ErrorIcon />
        <h1 className="text-sm font-medium text-label-base mt-4 mb-8">{t`Something went wrong`}</h1>
        <Link href={routes.timeReports} prefetch={false}>
          <Button
            variant="filled"
            size="md"
            leftIcon={<Icon iconName="refresh-ccw" />}
            className="m-auto"
          >
            <span className="text-base font-medium">{t`Back to homepage`}</span>
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
